import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Credenciales para conectarse a firebase
const firebaseConfig = {
    apiKey: "AIzaSyAKcl-44bncTTzI2u8-Vkt8NvUBj8rVix0",
    authDomain: "placam-868ca.firebaseapp.com",
    projectId: "placam-868ca",
    storageBucket: "placam-868ca.appspot.com",
    messagingSenderId: "471005081585",
    appId: "1:471005081585:web:3ba864f5d4f26d3cf79e50"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth()
const storage = firebase.storage()
const db = firebase.firestore()
const functions = firebase.functions()
export { db, auth, firebase, functions, storage }