import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
} from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import { db } from "../../firebase";

export const EditarColor = ({ props }) => {

    const [color, setColor] = useState(props.color)

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const updateColor = async () => {
        await db
            .collection("parameters")
            .doc('colores_vehiculos')
            .collection('colores')
            .doc(props.id)
            .update({
                color: color.toUpperCase(),
            }).then(() => {
                Swal.fire({ icon: "success", text: "Se ha actualizado el color con exito", });
            })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => onData()}>
                    <BorderColorIcon color="success" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    ACTUALIZAR EL COLOR: <strong>{props.color}</strong>
                </DialogTitle>
                <DialogContent>

                    <Box>
                        <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>COLOR</strong></p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }} value={color} onChange={(e) => setColor(e.target.value)} fullWidth variant="outlined" size="small" inputProps={{
                            maxLength: 8,
                        }} />
                    </Box>

                </DialogContent >
                <DialogActions>

                    <Button
                        size="small"
                        color="warning"
                        variant="outlined"
                        onClick={() => { setOpen(false) }}
                    >Regresar</Button>
                    <Button
                        disabled={
                            color === ''
                        }
                        color="success"
                        variant="contained"
                        size="small"
                        onClick={() => updateColor()
                        }>Actualizar</Button>

                </DialogActions>
            </Dialog >
        </>
    );
};