import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import edificio_f from './../assets/ciudad-destellos-luz-calles-noche.jpg'
import { login } from '../redux/actions/account/authActions'
import logo from './../assets/service_smart_logo2.png'

import InputValidation from '../components/utils/InputValidation';
import { Typography, FormControl, FormControlLabel, Checkbox } from '@mui/material';

const theme = createTheme();

export const LoginPage = () => {

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { error, loading } = userLogin
    const [email, setEmail] = useState({ campo: '', valido: true })
    const [password, setPassword] = useState({ campo: '', valido: null })

    const submitHandler = (e) => {
        e.preventDefault()
        if (email.valido === true && password.valido === true) {
            dispatch(login({
                'email': email.campo,
                'password': password.campo
            }))
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                {/* FORM */}

                <Grid container
                    justifyContent="center"
                    alignItems="center" item xs={12} sm={8} md={5} component={Paper} elevation={6}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >

                        <Box>
                            <img width={200} src={logo} alt="Logo" />
                        </Box>
                        <Box>
                            <p style={{ fontSize: "30px", marginBottom: "12px", marginTop: "12px" }}><strong>Iniciar Sesion</strong></p>
                        </Box>
                        <Box px={2} pb={2}>
                            <p style={{ fontSize: "12px", marginBottom: "6px" }}><strong>Correo Electrónico</strong></p>


                            <InputValidation
                                estado={email}
                                cambiarEstado={setEmail}
                                type="email"
                                name="email"
                            />

                            <FormControl fullWidth>
                                <p style={{ fontSize: "12px", marginBottom: "6px", marginTop: "0px" }}><strong>Contraseña</strong></p>
                                <InputValidation
                                    estado={password}
                                    cambiarEstado={setPassword}
                                    type="password"
                                    name="password"
                                    helperText="La contraseña debe tener al menos 8 caracteres"
                                    patterns={/^.{8,30}$/}
                                />
                            </FormControl>

                            <Box pt={1} pb={2}>
                                <Button
                                    disabled={!password.campo || !email.campo || !email.valido || !password.valido}
                                    onClick={submitHandler}
                                    fullWidth
                                    variant="contained"
                                >
                                    Iniciar sesion
                                </Button>
                            </Box>

                            <p style={{ fontSize: "14px", marginTop: "6px" }}>© Placam 2023</p>

                        </Box>
                    </Box>
                </Grid>

                {/* BACKGROUND IMAGE */}
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${edificio_f})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />

            </Grid>
        </ThemeProvider>
    );
}