import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db, firebase, auth } from "../../firebase";
import { useDispatch, useSelector } from 'react-redux';

export const DeleteUsuario = ({ props }) => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [open, setOpen] = useState(null);

    const [lastEmail, setLastEmail] = useState('');
    const [lastPassword, setLastPassword] = useState('');

    const onData = () => {
        setOpen(true)
    }

    const deleteUsuarioFunction = async () => {
        /* await db.collection('usuarios').doc(props.id).delete().then(() => {

            Swal.fire({ icon: "success", text: "Se ha eliminado el usuario con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); }); */
        /* try {
            if (props.name != 'ADMINISTRADOR') {
                // await db.collection('usuarios').doc(props.id).delete();
                await firebase.auth().deleteUser(props.id);
                console.log('Usuario eliminado correctamente en Authentication');
                setOpen(false)
                Swal.fire({ icon: "success", text: "Se ha eliminado el usuario con exito", });
            } else {
                setOpen(false)
                Swal.fire({ icon: "warning", text: "El usuario actual de administrador no puede ser eliminado", });
            }
        } catch (error) {
            console.error('Error al eliminar usuario en Authentication:', error);
        } */
        console.log('props', props)
        console.log('props.email', props.email)
        console.log('props.password', props.password)

        if (props.name != 'ADMINISTRADOR') {
            try {
                const currentUser = firebase.auth().currentUser;

                if (currentUser) {
                    // Reautenticar al usuario antes de la eliminación
                    /* const credential = firebase.auth.EmailAuthProvider.credential(props.email, props.password);
                    await currentUser.reauthenticateWithCredential(credential); */
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(props.email, props.password)
                        .then(async (userCredential) => {
                            // Signed in
                            const user = userCredential.user;
                            console.log("Sign In SuccessFul!");
                            console.log('currentUser', currentUser)

                            // Eliminar al usuario después de la reautenticación
                            await db.collection('usuarios').doc(props.id).delete()
                            await user.delete();
                            console.log('Usuario eliminado correctamente en Authentication');
                            setOpen(false)
                            Swal.fire({ icon: "success", text: "Se ha eliminado el usuario exitosamente, por su seguridad se ha cerrado sesion en caso de ser terceras personas", });

                            /* const credential2 = firebase.auth.EmailAuthProvider.credential(lastEmail, lastPassword);
                            await currentUser.reauthenticateWithCredential(credential2);
     
                            console.log('credential2', credential2); */
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                        });


                    // const credential2 = firebase.auth.EmailAuthProvider.credential(lastEmail, lastPassword);
                    // await currentUser.reauthenticateWithCredential(credential2);

                }
            } catch (error) {
                console.error('Error al eliminar usuario en Authentication:', error);
            }
        } else {
            setOpen(false)
            Swal.fire({ icon: "warning", text: "El usuario actual de administrador no puede ser eliminado", });
        }
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <DeleteIcon color="error" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    ¿ESTA SEGURO DE ELIMINAR EL USUARIO <strong>{props.name}</strong>?
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} size="small"><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        setLastEmail(userInfo.email)
                        setLastPassword(userInfo.password)
                        deleteUsuarioFunction()
                        /* datosFlota() */
                    }} variant="contained"
                        color="error"
                        size="small"
                    >
                        {" "}
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};