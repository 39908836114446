import {
  Typography,
  Box,
  Paper,
  Link
} from "@mui/material";

import React, { useEffect, useState, useRef } from "react";

import "react-datepicker/dist/react-datepicker.css";

import AbputUsImage from './../assets/service_smart_logo2.png'

export const AboutPage = () => {


  return (
    <>
      <Box py={2} px={2}>
        <Typography variant="h4" gutterBottom>
          Servicesmart S.A.
        </Typography>
        <Paper elevation={2}>
          <Box py={2} px={2}>
            <Box>
              <img width={450} src={AbputUsImage} alt="Logo" />
            </Box>
            <Typography gutterBottom>
              Servicesmart S.A. es una de las empresas industriales populares ubicadas en Coop. Bastión Chico Mz. 265 Solar 5, Guayaquil listado en Servicio de Merchandising en Guayaquil, Empresa Industrial en Guayaquil, Comercial y Proveedor de Equipos Industriales en Guayaquil.
            </Typography>
            <Typography gutterBottom>
              <strong>
                Detalles de contacto y Horas Laborales
              </strong>
            </Typography>
            <Typography gutterBottom>
              <strong>
                Direccion:
              </strong>
            </Typography>
            <Typography gutterBottom>
              Cooperativa. Bastión Chico Mz. 265 solares 5, Guayaquil,
            </Typography>
            <Typography gutterBottom>
              <strong>
                Teléfono:
              </strong>
            </Typography>
            <Typography gutterBottom>
              0998608611 - 0987735681 - 0998608758
            </Typography>
            <Typography gutterBottom>
              <strong>
                Horario de apertura:
              </strong>
            </Typography>
            <Typography gutterBottom>
              Lunes: 08:00-16:00
            </Typography>
            <Typography gutterBottom>
              Martes: 08:00-16:00
            </Typography>
            <Typography gutterBottom>
              Miércoles: 08:00-16:00
            </Typography>
            <Typography gutterBottom>
              Jueves: 08:00-16:00
            </Typography>
            <Typography gutterBottom>
              Viernes: 08:00-16:00
            </Typography>
            <Typography gutterBottom>
              Sábado: 00:00-00:00
            </Typography>
            <Typography gutterBottom>
              Domingo: 00:00-00:00
            </Typography>
            <Typography gutterBottom>
              <strong>
                Más sobre Servicesmart S.A.
              </strong>
            </Typography>
            <Typography gutterBottom>
              Servicesmart S.A. es una empresa dedicada a la fabricación y comercialización de fundas plásticas de todo tipo para la industria.
            </Typography>
            <Typography gutterBottom>
              <strong>
                Mapa de Servicesmart S.A.
              </strong>
            </Typography>
            <Typography gutterBottom>
              <Link href="https://www.google.com/maps?ll=-2.26379,-79.89562&z=15&t=m&hl=es-ES&gl=US&mapclient=embed&q=2%C2%B015%2749.6%22S+79%C2%B053%2744.2%22W+-2.263790,+-79.895620@-2.26379,-79.8956199" underline="hover" target="_blank" rel="noopener noreferrer">
                https://www.google.com/maps?ll=-2.26379,-79.89562&z=15&t=m&hl=es-ES&gl=US&mapclient=embed&q=2%C2%B015%2749.6%22S+79%C2%B053%2744.2%22W+-2.263790,+-79.895620@-2.26379,-79.8956199
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Box>

    </>
  )
}
