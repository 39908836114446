import {
  Box,
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Collapse,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem
} from "@mui/material";

import { db, firebase } from "../firebase";

import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from 'react-redux';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  esES
} from '@mui/x-data-grid';
import { DeleteVehiculo } from "./DeleteVehiculo";
import Swal from "sweetalert2";
import { EditarVehiculo } from "./EditarVehiculo";
import axios from 'axios';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import moment from 'moment'

import * as XLSX from "xlsx/xlsx.mjs";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import DatePicker from "react-datepicker";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "@mui/x-date-pickers";
import { PdfRegistros } from "./PdfRegistros";

export const HomePage = () => {

  const [openPdf, setOpenPdf] = useState(false);

  const today = new Date();
  const horaActual = today.getHours();
  const minutoActual = today.getMinutes();

  const userAuth = useSelector(state => state.userAuth)
  const { loadin, userInfo } = userAuth

  const [tokenNotification, setTokenNotification] = useState('');

  const getTokenNotification = async () => {
    const agendaDB2 = await db.collection("usuarios").where('uid', '==', userInfo.uid).get()

    const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    console.log('data2', data2)

    setTokenNotification(data2[0].token_notification)

  }

  const sendNotification = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer AAAA2IQ5JaY:APA91bEDaAULEtZDDhRd8vaOq64sZL12JyiEGTkkLkVjE8s5azx7S-rgP3cviGgzuAcKGnhgqSOKsTor9eSGvJHUVt5doClqaR2joYUwuI3DNhmXb5AiQrH7C2N8rZ9kztpZsgN0Z57V");

    var raw = JSON.stringify({
      "notification": {
        "title": "Se ha detectado una nueva placa vehicular",
        "body": plateCode
      },
      "priority": "high",
      "to": `${tokenNotification}`
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  const [horaEntrada, setHoraEntrada] = useState(null)

  const [horaSalida, setHoraSalida] = useState(null)

  const [fecha, setFecha] = useState(null)
  const [fechaSalida, setFechaSalida] = useState(null)

  const [open, setOpen] = useState(false);

  const [openRegisterVehicle, setOpenRegisterVehicle] = useState(false);
  const [openNewRegisterVehicle, setOpenNewRegisterVehicle] = useState(false);

  const [isNewRegisterModalVisible, setIsNewRegisterModalVisible] = useState(true);

  const [openConfirmNewRegister, setOpenConfirmNewRegister] = useState(false);

  const [plateCode, setPlateCode] = useState('');

  const videoUrl = 'http://127.0.0.1:5000/';

  const videoRef = useRef(null);

  const [placa, setPlaca] = useState('')
  const [propietario, setPropietario] = useState('')
  const [cedula, setCedula] = useState('')
  const [listMarcas, setListMarcas] = useState([])
  const [marca, setMarca] = useState('')
  const [listColors, setListColors] = useState([])
  const [color, setColor] = useState('')
  const [observaciones, setObservaciones] = useState('')

  const [vehiculoLocal, setVehiculoLocal] = useState('')

  const [total, setTotal] = useState(0);

  const [data, setData] = useState('')

  const [dataPlacaEncontrada, setDataPlacaEncontrada] = useState([])

  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );

  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([])

  //Se hace una consulta a la base para obtener todos los vehiculos
  const getVehiculos = (startAfterDoc, persistMessages = []) => {
    if (endDate && startDate) {
      let ref = db.collection("vehiculos");
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('fecha_creacion', '>=', startfulldate)
      ref = ref.where('fecha_creacion', '<=', endfulldate)

      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
      ref.onSnapshot(snapshot => {
        const totalCount = snapshot.size
        const data = [
          ...persistMessages,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ];
        setData(data)
        setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
        setIsLastPage(totalCount < 1)
      })
    }
  }

  const startCamera = async () => {
    try {
      // Realiza una solicitud a la API de Flask para obtener el flujo de video
      const response = await fetch('http://127.0.0.1:5000/video_feed'); // Actualiza la URL
      if (!response.ok) {
        throw new Error('No se pudo obtener el flujo de video');
      }

      // Convierte la respuesta a un objeto de tipo Blob
      const videoBlob = await response.blob();

      // Crea una URL de objeto para el Blob
      const videoUrl = URL.createObjectURL(videoBlob);

      // Si ya hay una referencia al video, desconéctala
      if (videoRef.current) {
        videoRef.current.src = '';
        videoRef.current.load();
      }

      // Asigna la URL del video al elemento <video>
      videoRef.current.src = videoUrl;
      console.log('videoUrl', videoUrl);
    } catch (error) {
      console.error('Error al iniciar la cámara:', error);
    }
  };

  function CustomFooterTotalComponent(props) {
    console.log(props)
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    //LoadData()
  }

  const getMarcas = () => {
    let ref = db.collection('parameters').doc('marcas_vehiculos').collection('marcas');
    ref.onSnapshot((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setListMarcas(data);
    });
  }

  const getColores = () => {
    let ref = db.collection('parameters').doc('colores_vehiculos').collection('colores');
    ref.onSnapshot((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setListColors(data);
    });
  }

  const [videoKey, setVideoKey] = useState(0);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://placam.online');
      setPlateCode(response.data.plate_code);
      /* console.log(`response.data.plate_code ${response.data.plate_code}`); */
      if (response.data.plate_code !== '') {
        if (response.data.plate_code.toString().length === 8) {
          console.log(`response.data.plate_code.toString() ${response.data.plate_code.toString()}`)
          const agendaDB3 = await db
            .collection('vehiculos')
            .where('placa', '==', response.data.plate_code.toString())
            .orderBy('fecha_creacion', 'desc')
            .get();

          const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setDataPlacaEncontrada(data3);

          console.log('plateCode', response.data.plate_code);
          console.log('data3[0]', data3[0]);

          if (data3[0] === undefined) {
            console.log('La placa detectada no se encuentra registrada');

            // Restablecer la visibilidad del modal al recibir una nueva placa
            setIsNewRegisterModalVisible(true);

            setOpenNewRegisterVehicle(true);
            sendNotification()
            setFecha(today)
            setHoraEntrada(Date(0, 0, 0, horaActual, minutoActual))
          } else {

            if (data3[0] && data3[0].fecha_salida && data3[0].hora_salida) {
              setOpenRegisterVehicle(true);
              sendNotification()
              console.log(`dataPlacaEncontrada ${dataPlacaEncontrada[0].placa}`);
              console.log('La placa detectada ya se encuentra registrada');
              setFecha(today)
              setHoraEntrada(Date(0, 0, 0, horaActual, minutoActual))
            } else {
              await db.collection('vehiculos').doc(data3[0].id).update({
                fecha_salida: today,
                hora_salida: today,
              })
              Swal.fire({ icon: "success", text: `Se ha ingresado la fecha y hora de salida del vehiculo con placa ${data3[0].placa.toUpperCase()} automaticamente` });
            }
          }
        } else {
          console.log('Es menor a 8 caracteres')
        }
      }
    } catch (error) {
      console.error('Error al obtener el código de la placa:', error);
      setTimeout(fetchData, 1000);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getTokenNotification()
    getVehiculos()
    getMarcas()
    getColores()
    fetchData();
    startCamera()
    setVideoKey((prevKey) => prevKey + 1);

  }, [])

  useEffect(() => {
    // Cambia la clave del video para reiniciar la fuente del video cuando se detecta una nueva placa
    setVideoKey((prevKey) => prevKey + 1);
    /* setInterval(fetchData, startCamera, 1000); */
    const intervalo = setInterval(() => {
      console.log('plateCode', plateCode)
      console.log('placa', placa)
      if (plateCode != placa) {
        // Lógica que se ejecutará si la condición es verdadera
        console.log('La condición es verdadera, ejecutando la lógica...');
        fetchData();
        startCamera()
      }
      // Si no, no se ejecuta nada

    }, 1000); // Intervalo de 1000 milisegundos (1 segundo)

    // Limpia el intervalo cuando el componente se desmonta o la condición cambia
    return () => clearInterval(intervalo);
  }, [plateCode]);

  const columns = [
    { field: 'placa', headerName: 'PLACA', width: 110 },
    { field: 'propietario', headerName: 'PROPIETARIO', width: 200 },
    { field: 'cedula', headerName: 'CEDULA', width: 140 },
    { field: 'marca', headerName: 'MARCA', width: 180 },
    { field: 'color', headerName: 'COLOR', width: 140 },
    { field: 'vehiculo_local_empresa', headerName: 'VEHICULO LOCAL EMPRESA', width: 220 },
    {
      field: 'fecha_creacion', headerName: 'FECHA DE CREACION', type: 'dateTime', width: 190,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'fecha_ingreso', headerName: 'FECHA INGRESO', type: 'dateTime', width: 150,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
    },
    {
      field: 'hora_ingreso', headerName: 'HORA INGRESO', type: 'dateTime', width: 140,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('h:mm:ss a'),
    },
    {
      field: 'fecha_salida', headerName: 'FECHA SALIDA', width: 150,
      renderCell: (params) => {
        const horaSalida = params.row.fecha_salida === undefined || params.row.fecha_salida === null || params.row.fecha_salida === '' ? '' : params.row.fecha_salida && moment(new Date(params.row.fecha_salida.seconds * 1000).toISOString()).format('DD/MM/YYYY');

        return horaSalida
      }
    },
    /* {
      field: 'hora_salida', headerName: 'HORA SALIDA', type: 'dateTime', width: 130,
      valueGetter: ({ value }) => value === undefined || value === null || value === '' ? '' : value && moment(new Date(value.seconds * 1000).toISOString()).format('h:mm:ss a'),
    }, */
    {
      field: 'hora_salida', headerName: 'HORA SALIDA', width: 140,
      renderCell: (params) => {
        const horaSalida = params.row.hora_salida === undefined || params.row.hora_salida === null || params.row.hora_salida === '' ? '' : params.row.hora_salida && moment(new Date(params.row.hora_salida.seconds * 1000).toISOString()).format('h:mm:ss a');

        return horaSalida
      }
    },
    //
    {
      field: 'x', headerName: 'HORAS TOTALES', width: 180,
      renderCell: (params) => {
        var validacionResp = '';

        if (params.row.hora_salida === undefined || params.row.hora_salida === null || params.row.hora_salida === '') {
        } else {
          const horaIngreso = params.row.hora_ingreso.toDate();
          const horaSalida = params.row.hora_salida.toDate();

          const diferencia = horaSalida - horaIngreso;
          const horas = Math.floor(diferencia / 3600000); // 1 hora = 3600000 milisegundos
          const minutos = Math.floor((diferencia % 3600000) / 60000);

          const tiempoTotal = `${horas} horas y ${minutos} minutos`;

          validacionResp = tiempoTotal
        }

        return validacionResp
      }
    },
    //
    {
      field: 'fecha_actualizacion', headerName: 'FECHA DE ACTUALIZACION', type: 'dateTime', width: 210,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    { field: 'observaciones', headerName: 'OBSERVACIONES', width: 600 },
    {
      field: '1', headerName: 'ACTUALIZAR', width: 120,
      renderCell: (params) => {
        return <EditarVehiculo props={params.row} />
      }
    },
    {
      field: '2', headerName: 'ELIMINAR', width: 120,
      renderCell: (params) => {
        return <DeleteVehiculo props={params.row} />
      }
    },
  ];

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    const reData = [
      ...data.map(doc => {
        return {
          PLACA: doc.placa,
          PROPIETARIO: doc.propietario,
          CEDULA: doc.cedula,
          MARCA: doc.marca,
          COLOR: doc.color,
          VEHICULO_LOCAL_EMPRESA: doc.vehiculo_local_empresa,
          FECHA_DE_CREACION: moment(doc.fecha_creacion.toDate().toString()).format(
            "DD/MM/YYYY"
          ),
          FECHA_INGRESO: moment(doc.fecha_ingreso.toDate().toString()).format(
            "DD/MM/YYYY"
          ),
          HORA_INGRESO: moment(doc.hora_ingreso.toDate().toString()).format(
            "h:mm:ss a"
          ),
          FECHA_SALIDA: doc.fecha_salida === undefined || doc.fecha_salida === null || doc.fecha_salida === '' ? '' : moment(doc.fecha_salida.toDate().toString()).format(
            "DD/MM/YYYY"
          ),
          HORA_SALIDA: doc.hora_salida === undefined || doc.hora_salida === null || doc.hora_salida === '' ? '' : moment(doc.hora_salida.toDate().toString()).format(
            "h:mm:ss a"
          ),
          OBSERVACIONES: doc.observaciones,
        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Reporte_vehicular");
    XLSX.writeFile(wb, `Reporte_vehicular ${date1}.xlsx`)
  }

  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />

    </GridToolbarContainer>
  );

  const createNewVehicle = async () => {

    if (fechaSalida === null && horaSalida !== null) {
      await db
        .collection("vehiculos")
        .doc()
        .set({
          placa: placa.toUpperCase(),
          propietario: propietario.toUpperCase(),
          cedula: cedula,
          marca: marca.toUpperCase(),
          color: color.toUpperCase(),
          fecha_creacion: firebase.firestore.FieldValue.serverTimestamp(),
          vehiculo_local_empresa: vehiculoLocal,
          fecha_ingreso: fecha,
          hora_ingreso: horaEntrada,
          hora_salida: horaSalida,
          observaciones: observaciones
        })
    } else if (horaSalida === null && fechaSalida !== null) {
      await db
        .collection("vehiculos")
        .doc()
        .set({
          placa: placa.toUpperCase(),
          propietario: propietario.toUpperCase(),
          cedula: cedula,
          marca: marca.toUpperCase(),
          color: color.toUpperCase(),
          fecha_creacion: firebase.firestore.FieldValue.serverTimestamp(),
          vehiculo_local_empresa: vehiculoLocal,
          fecha_ingreso: fecha,
          fecha_salida: fechaSalida,
          hora_ingreso: horaEntrada,
          observaciones: observaciones
        })
    } else if (horaSalida === null && fechaSalida === null) {
      await db
        .collection("vehiculos")
        .doc()
        .set({
          placa: placa.toUpperCase(),
          propietario: propietario.toUpperCase(),
          cedula: cedula,
          marca: marca.toUpperCase(),
          color: color.toUpperCase(),
          fecha_creacion: firebase.firestore.FieldValue.serverTimestamp(),
          vehiculo_local_empresa: vehiculoLocal,
          fecha_ingreso: fecha,
          hora_ingreso: horaEntrada,
          observaciones: observaciones
        })
    } else if (horaSalida !== null && fechaSalida !== null) {
      await db
        .collection("vehiculos")
        .doc()
        .set({
          placa: placa.toUpperCase(),
          propietario: propietario.toUpperCase(),
          cedula: cedula,
          marca: marca.toUpperCase(),
          color: color.toUpperCase(),
          fecha_creacion: firebase.firestore.FieldValue.serverTimestamp(),
          vehiculo_local_empresa: vehiculoLocal,
          fecha_ingreso: fecha,
          fecha_salida: fechaSalida,
          hora_ingreso: horaEntrada,
          hora_salida: horaSalida,
          observaciones: observaciones
        })
    } else {
      await db
        .collection("vehiculos")
        .doc()
        .set({
          placa: placa.toUpperCase(),
          propietario: propietario.toUpperCase(),
          cedula: cedula,
          marca: marca.toUpperCase(),
          color: color.toUpperCase(),
          fecha_creacion: firebase.firestore.FieldValue.serverTimestamp(),
          vehiculo_local_empresa: vehiculoLocal,
          fecha_ingreso: fecha,
          fecha_salida: fechaSalida,
          hora_ingreso: horaEntrada,
          hora_salida: horaSalida,
          observaciones: observaciones
        })
    }

    setPlaca('')
    setPropietario('')
    setCedula('')
    setMarca('')
    setColor('')
    setVehiculoLocal('')
    setFecha(null)
    setFechaSalida(null)
    setHoraEntrada(null)
    setHoraSalida(null)
    setObservaciones('')

    setOpenConfirmNewRegister(false)

    Swal.fire({ icon: "success", text: `Se ha creado un nuevo registro para el vehiculo con placa ${placa.toUpperCase()} con exito` });

  }

  const handleInputChangePropietario = (e) => {
    const inputValue = e.target.value;

    // Usar una expresión regular para validar el valor ingresado
    if (/^[a-zA-Z\s]*$/.test(inputValue)) {
      setPropietario(inputValue);
    }
  };

  const handleInputChangeCedula = (e) => {
    const inputValue = e.target.value;

    // Usar una expresión regular para validar el valor ingresado
    if (/^[0-9]*$/.test(inputValue)) {
      setCedula(inputValue);
    }
  };

  const handleInputChangeMarca = (e) => {
    const inputValue = e.target.value;

    // Usar una expresión regular para validar el valor ingresado
    if (/^[a-zA-Z\s]*$/.test(inputValue)) {
      setMarca(inputValue);
    }
  };

  const handleInputChangeColor = (e) => {
    const inputValue = e.target.value;

    // Usar una expresión regular para validar el valor ingresado
    if (/^[a-zA-Z\s]*$/.test(inputValue)) {
      setColor(inputValue);
    }
  };

  const handlePlacaChange = (e) => {
    const inputValue = e.target.value.toUpperCase();

    // Validar las primeras tres letras
    let letras = inputValue.substring(0, 3).replace(/[^A-Z]/g, ''); // Solo letras

    // Agregar guion después de las 3 letras si no existe
    let guion = inputValue[3] === '-' ? '-' : '';

    // Validar los últimos cuatro números
    let numeros = inputValue.substring(4, 8).replace(/[^0-9]/g, ''); // Solo números

    const formattedInput = `${letras}${guion}${numeros}`;

    setPlaca(formattedInput);
  };

  const setOpenPdfFunction = () => {
    setOpenPdf(true)
    /* setTimeout(function () {
      setOpenPdf(false);
    }, 1000); */
  }

  return (
    <>

      <Grid px={2} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Paper>
            <Box px={2} py={1}>
              <Box pb={1}>
                <Typography variant="h4" component="h2">
                  Camara de registro vehiculos
                </Typography>
              </Box>
              {/* Usa la clave del video como parte de la URL para forzar la recarga del componente */}
              <iframe
                key={videoKey}
                src={videoUrl}
                width="650"
                height="520"
                frameborder="0"
              ></iframe>
            </Box>
          </Paper>

        </Grid>
        <Grid item xs={6}>

          <Paper>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box px={2} py={2}>
                <Box pb={1}>
                  <Typography variant="h4" component="h2">
                    Nuevo registro vehicular
                  </Typography>
                </Box>

                <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>PLACA</strong></p>
                <TextField sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }} value={placa} onChange={handlePlacaChange/* (e) => setPlaca(e.target.value) */} fullWidth variant="outlined" size="small" inputProps={{
                  maxLength: 8,
                }} />

                <Box py={2}>
                  <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>PROPIETARIO</strong></p>
                  <TextField sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }} value={propietario} onChange={handleInputChangePropietario}/* onChange={(e) => setPropietario(e.target.value)} */ fullWidth variant="outlined" size="small" />
                </Box>

                <Box pb={2}>
                  <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>CEDULA</strong></p>
                  <TextField sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }} value={cedula} onChange={handleInputChangeCedula}/* onChange={(e) => setPropietario(e.target.value)} */ fullWidth variant="outlined" size="small" inputProps={{
                    maxLength: 10,
                  }} />
                </Box>

                <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>MARCA</strong></p>
                {/* <TextField sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }} value={marca} onChange={handleInputChangeMarca} fullWidth variant="outlined" size="small" /> */}

                <Select sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }}
                  fullWidth
                  size="small"
                  value={marca}
                  label="MARCA"
                  onChange={(e) => setMarca(e.target.value)}>
                  {listMarcas &&
                    listMarcas.map(item => (
                      <MenuItem key={item.marca} value={item.marca} >
                        {item.marca}
                      </MenuItem>
                    ))}
                </Select>

                <Box py={2}>
                  <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>COLOR</strong></p>
                  {/* <TextField sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }} value={color} onChange={handleInputChangeColor} fullWidth variant="outlined" size="small" /> */}
                  <Select sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                    fullWidth
                    size="small"
                    value={color}
                    label="MARCA"
                    onChange={(e) => setColor(e.target.value)}>
                    {listColors &&
                      listColors.map(item => (
                        <MenuItem key={item.color} value={item.color} >
                          {item.color}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>

                <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>VEHICULO LOCAL DE LA EMPRESA</strong></p>
                <Select
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                  fullWidth
                  size="small"
                  value={vehiculoLocal}
                  onChange={(e) => setVehiculoLocal(e.target.value)}
                >
                  <MenuItem value="SI">SI</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </Select>

                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Box py={2}>
                      <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>FECHA INGRESO</strong></p>
                      <FormControl fullWidth>
                        <DesktopDatePicker
                          renderInput={(props) => <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                          }} {...props} size="small" />}
                          value={fecha}
                          onChange={(newValue) => {
                            setFecha(newValue);
                          }}
                          inputFormat="dd/MM/yyyy"
                          minDate={today}
                          maxDate={today}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box py={2}>

                      <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>FECHA SALIDA</strong></p>
                      <FormControl fullWidth>
                        <DesktopDatePicker
                          renderInput={(props) => <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                          }} {...props} size="small" />}
                          value={fechaSalida}
                          onChange={(newValue) => {
                            setFechaSalida(newValue);
                          }}
                          inputFormat="dd/MM/yyyy"
                          minDate={today}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Box pb={2}>
                      <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>HORA ENTRADA</strong></p>
                      <FormControl fullWidth>
                        <TimePicker
                          renderInput={(props) => <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                          }} {...props} size="small" />}
                          value={horaEntrada}
                          onChange={(newValue) => {
                            setHoraEntrada(newValue);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box pb={2}>

                      <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>HORA SALIDA</strong></p>
                      <FormControl fullWidth>
                        <TimePicker
                          renderInput={(props) => <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                          }} {...props} size="small" />}
                          value={horaSalida}
                          onChange={(newValue) => {
                            setHoraSalida(newValue);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Box pb={2}>
                  <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>OBSERVACIONES</strong></p>
                  <TextField sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }} value={observaciones} onChange={(e) => { setObservaciones(e.target.value) }}/* onChange={(e) => setPropietario(e.target.value)} */ fullWidth variant="outlined" size="small" multiline rows={4} />
                </Box>

                <Button disabled={
                  placa === '' ||
                  propietario === '' ||
                  cedula === '' ||
                  marca === '' ||
                  color === '' ||
                  vehiculoLocal === '' ||
                  fecha === null ||
                  horaEntrada === null
                } fullWidth variant="contained" onClick={() => setOpenConfirmNewRegister(true)}>Registrar</Button>
              </Box>
            </LocalizationProvider>
          </Paper>

        </Grid>
      </Grid >

      {/* <Grid py={6} container direction="row" justifyContent="center" alignItems="center">
        <Box px={2}>
          <Button variant="contained" color="success" onClick={() => { sendNotification() }}>Actualizar</Button>
        </Box>
        <Button variant="contained" color="error" >Eliminar</Button>
      </Grid> */}

      < Box px={2} py={3} >
        <Paper elevation={2}>
          <Box px={2} pb={2}>
            <Box pb={1}>
              <Typography variant="h4" component="h2">
                Lista de registro vehicular
              </Typography>
            </Box>

            <Box display="flex" style={{ textTransform: "uppercase" }} >
              <Typography variant="button">
                <strong>Desde</strong>{" "}
                {startDate &&
                  moment(startDate.toString()).format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
              <Typography variant="button" style={{ paddingLeft: 10 }}>
                <strong>Hasta</strong>{" "}
                {endDate &&
                  moment(endDate.toString()).format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
                <Box display="flex" alignItems="center" pb={1}>
                  <Button
                    style={{ fontSize: "11px", marginRight: "5px" }}
                    disableElevation
                    color="primary"
                    variant="contained"
                    size="small"
                    endIcon={open === true ? <ArrowCircleUpIcon fontSize="small" /> : <ArrowCircleDownIcon fontSize="small" />
                    } onClick={() => setOpen(!open)}>
                    Filtrar fecha
                  </Button>
                  <Button
                    style={{ fontSize: "11px", marginRight: "5px" }}
                    disableElevation
                    color="success"
                    variant="outlined"
                    size="small"
                    endIcon={<CloudDownloadIcon fontSize="small" />}
                    onClick={async () => handleExport()}
                  >
                    Descargar reporte
                  </Button>


                  {/* Renderiza PdfRegistros como un componente */}
                  {/* {openPdf && <PdfRegistros info={data} />} */}
                  {data.length === 0 ? <Button
                    style={{ fontSize: "11px" }}
                    disableElevation
                    color="error"
                    variant="outlined"
                    size="small"
                    endIcon={<CloudDownloadIcon fontSize="small" />}
                    onClick={() => {
                      {/* <PdfRegistros info={data} /> */ }
                      /* setOpenPdfFunction() */
                    }/* setOpenPdfFunction() */} // Abre el diálogo cuando haces clic en el botón
                  >
                    Descargar pdf
                  </Button> : <PdfRegistros info={data} />}
                </Box>
              </Box>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
                <Box pb={1}>
                  <Button
                    style={{ fontSize: "11px" }}
                    disableElevation
                    color="primary"
                    variant="outlined" size="small"
                    onClick={() => getVehiculos()}
                  >
                    Buscar
                  </Button>
                </Box>
              </Collapse>
            </Box>

            <Paper>
              <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                  density="compact"
                  rows={data ? data : []}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  columns={columns}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterTotalComponent,
                  }}

                  onStateChange={(state) => {
                    console.log(state.pagination.rowCount);
                    setTotal(state.pagination.rowCount)
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Paper>
      </Box >

      <Dialog open={openConfirmNewRegister} maxWidth="xs" fullWidth>
        <DialogTitle>¿ESTA SEGURO DE CREAR UN NUEVO REGISTRO PARA EL VEHICULO {placa.toUpperCase()}?</DialogTitle>

        <DialogActions dividers>
          <Button
            loadingPosition="end"
            size="small"
            variant="outlined"
            color="warning"
            onClick={() => setOpenConfirmNewRegister(false)}
          >
            NO, REGRESAR
          </Button>
          <Button
            loadingPosition="end"
            size="small"
            variant="contained"
            color="success"
            onClick={() => createNewVehicle()}
          >
            SI, REGISTRAR
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog si la placa ya esta registrada */}
      <Dialog open={openRegisterVehicle} maxWidth="xs" fullWidth>
        <DialogTitle>LA PLACA DETECTADA YA SE ENCUENTRA REGISTRADA</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            ¿Desea agregar automaticamente los datos en el formulario para un nuevo registro?
          </Typography>
        </DialogContent>
        <DialogActions dividers>
          <Button
            loadingPosition="end"
            size="small"
            variant="outlined"
            color="error"
            onClick={() => setOpenRegisterVehicle(false)}
          >
            NO, SALIR
          </Button>
          <Button
            loadingPosition="end"
            size="small"
            variant="contained"
            color="success"
            onClick={() => {

              if (dataPlacaEncontrada[0] === undefined) {
                console.log('dataPlacaEncontrada[0] es undefined')
              } else {
                setPlaca(dataPlacaEncontrada[0].placa)
                setPropietario(dataPlacaEncontrada[0].propietario)
                setMarca(dataPlacaEncontrada[0].marca)
                setColor(dataPlacaEncontrada[0].color)
                setCedula(dataPlacaEncontrada[0].cedula)
              }

              setOpenRegisterVehicle(false)

            }}
          >
            SI, AGREGAR
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog si la placa no esta registrada */}
      <Dialog open={openNewRegisterVehicle && isNewRegisterModalVisible} maxWidth="xs" fullWidth>
        <DialogTitle>LA PLACA DETECTADA NO SE ENCUENTRA REGISTRADA</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            ¿Desea agregar el codigo de placa al formulario para comenzar un nuevo registro?
          </Typography>
        </DialogContent>
        <DialogActions dividers>
          <Button
            loadingPosition="end"
            size="small"
            variant="outlined"
            color="error"
            onClick={() => {
              // Cerrar el modal y deshabilitar su visibilidad
              setOpenNewRegisterVehicle(false);
              setIsNewRegisterModalVisible(false);
            }}
          >
            NO, SALIR
          </Button>
          <Button
            loadingPosition="end"
            size="small"
            variant="contained"
            color="success"
            onClick={() => {

              if (plateCode === '') {
                console.log('plateCode esta vacia')
              } else {
                setPlaca(plateCode)
              }

              // Cerrar el modal y deshabilitar su visibilidad
              setOpenNewRegisterVehicle(false);
              setIsNewRegisterModalVisible(false);

            }}
          >
            SI, AGREGAR
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}
