import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    Select,
    MenuItem,
    FormControl
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db, firebase } from "../firebase";
import { useParams, useNavigate } from 'react-router-dom';

import BorderColorIcon from '@mui/icons-material/BorderColor';

import { TimePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export const EditarVehiculo = ({ props }) => {

    const today = new Date();

    const [placa, setPlaca] = useState(props.placa)
    const [propietario, setPropietario] = useState(props.propietario)
    const [cedula, setCedula] = useState(props.cedula)
    const [marca, setMarca] = useState(props.marca)
    const [fechaSalida, setFechaSalida] = useState(null)
    const [horaSalida, setHoraSalida] = useState(null)
    const [listMarcas, setListMarcas] = useState([])
    const [color, setColor] = useState(props.color)
    const [listColors, setListColors] = useState([])

    const params = useParams()

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const getMarcas = () => {
        let ref = db.collection('parameters').doc('marcas_vehiculos').collection('marcas');
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setListMarcas(data);
        });
    }

    const getColores = () => {
        let ref = db.collection('parameters').doc('colores_vehiculos').collection('colores');
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setListColors(data);
        });
    }

    useEffect(() => {
        getMarcas()
        getColores()
    }, [])

    useEffect(() => {
        // Convertir la hora de Firestore a un objeto de fecha válida
        const horaSalidaDate = props.hora_salida ? props.hora_salida.toDate() : null;
        setHoraSalida(horaSalidaDate);

        const fechaSalidaDate = props.fecha_salida ? props.fecha_salida.toDate() : null;
        setFechaSalida(fechaSalidaDate);
    }, [props.hora_salida, props.fecha_salida]);

    const updateVehicle = async () => {
        await db
            .collection("vehiculos")
            .doc(props.id)
            .update({
                placa: placa.toUpperCase(),
                propietario: propietario.toUpperCase(),
                cedula: cedula,
                marca: marca.toUpperCase(),
                color: color.toUpperCase(),
                fecha_salida: fechaSalida,
                hora_salida: horaSalida,
                fecha_actualizacion: firebase.firestore.FieldValue.serverTimestamp(),
            }).then(() => {
                Swal.fire({ icon: "success", text: "Se ha actualizado el vehiculo con exito", });
            })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    const handleInputChangePropietario = (e) => {
        const inputValue = e.target.value;

        // Usar una expresión regular para validar el valor ingresado
        if (/^[a-zA-Z\s]*$/.test(inputValue)) {
            setPropietario(inputValue);
        }
    };

    const handleInputChangeCedula = (e) => {
        const inputValue = e.target.value;

        // Usar una expresión regular para validar el valor ingresado
        if (/^[0-9]*$/.test(inputValue)) {
            setCedula(inputValue);
        }
    };

    const handleInputChangeMarca = (e) => {
        const inputValue = e.target.value;

        // Usar una expresión regular para validar el valor ingresado
        if (/^[a-zA-Z\s]*$/.test(inputValue)) {
            setMarca(inputValue);
        }
    };

    const handleInputChangeColor = (e) => {
        const inputValue = e.target.value;

        // Usar una expresión regular para validar el valor ingresado
        if (/^[a-zA-Z\s]*$/.test(inputValue)) {
            setColor(inputValue);
        }
    };

    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => onData()}>
                    <BorderColorIcon color="success" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    ACTUALIZAR VEHICULO/REGISTRO CON PLACA: <strong>{props.placa}</strong>
                </DialogTitle>
                <DialogContent>

                    <Box>
                        <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>PLACA</strong></p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }} value={placa} onChange={(e) => setPlaca(e.target.value)} fullWidth variant="outlined" size="small" inputProps={{
                            maxLength: 8,
                        }} />

                        <Box py={2}>
                            <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>PROPIETARIO</strong></p>
                            <TextField sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }} value={propietario} onChange={handleInputChangePropietario}/* onChange={(e) => setPropietario(e.target.value)} */ fullWidth variant="outlined" size="small" />
                        </Box>
                        <Box pb={2}>
                            <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>CEDULA</strong></p>
                            <TextField sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }} value={cedula} onChange={handleInputChangeCedula}/* onChange={(e) => setPropietario(e.target.value)} */ fullWidth variant="outlined" size="small" inputProps={{
                                maxLength: 10,
                            }} />
                        </Box>

                        <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>MARCA</strong></p>
                        {/* <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }} value={marca} onChange={handleInputChangeMarca} fullWidth variant="outlined" size="small" /> */}
                        <Select sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            fullWidth
                            size="small"
                            value={marca}
                            label="MARCA"
                            onChange={(e) => setMarca(e.target.value)}>
                            {listMarcas &&
                                listMarcas.map(item => (
                                    <MenuItem key={item.marca} value={item.marca} >
                                        {item.marca}
                                    </MenuItem>
                                ))}
                        </Select>

                        <Box py={2}>
                            <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>COLOR</strong></p>
                            {/* <TextField sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }} value={color} onChange={handleInputChangeColor} fullWidth variant="outlined" size="small" /> */}
                            <Select sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                                fullWidth
                                size="small"
                                value={color}
                                label="MARCA"
                                onChange={(e) => setColor(e.target.value)}>
                                {listColors &&
                                    listColors.map(item => (
                                        <MenuItem key={item.color} value={item.color} >
                                            {item.color}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Box>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>FECHA SALIDA</strong></p>
                            <FormControl fullWidth>
                                <DesktopDatePicker
                                    renderInput={(props) => <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }} {...props} size="small" />}
                                    value={fechaSalida}
                                    onChange={(newValue) => {
                                        setFechaSalida(newValue);
                                    }}
                                    inputFormat="dd/MM/yyyy"
                                />

                            </FormControl>
                            <Box py={2}>
                                <p style={{ fontSize: "11px", marginBottom: "6px", marginTop: "0px" }}><strong>HORA SALIDA</strong></p>
                                <FormControl fullWidth>
                                    <TimePicker
                                        renderInput={(props) => <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }} {...props} size="small" />}
                                        value={horaSalida}
                                        onChange={(newValue) => {
                                            setHoraSalida(newValue);
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </LocalizationProvider>
                    </Box>

                </DialogContent >
                <DialogActions>

                    <Button
                        size="small"
                        color="warning"
                        variant="outlined"
                        onClick={() => { setOpen(false) }}
                    >Regresar</Button>
                    <Button
                        disabled={
                            placa === '' ||
                            propietario === '' ||
                            marca === '' ||
                            color === ''
                        }
                        color="success"
                        variant="contained"
                        size="small"
                        onClick={() => updateVehicle()
                        }>Actualizar</Button>

                </DialogActions>
            </Dialog >
        </>
    );
};