import {
    ListItemText, Typography, Box, Button,
    Divider, Toolbar, IconButton, List, ListItem, ListItemButton,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, styled, createTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { ProfileMenu } from './ProfileMenu';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        //padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
const drawerWidth = 300;
export const Header = (props) => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    let theme = createTheme({
        palette: {
            mode: 'dark',
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#333",
                        color: "white",
                        boxSizing: "border-box",

                    }
                }
            }
        }
    });
    const container = window !== undefined ? () => window().document.body : undefined;
    const buttonStyle = {
        textTransform: 'none', // Esto evita la transformación automática a mayúsculas
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {
                    props.isAuth && <>
                        <AppBar
                            position='absolute'
                            // elevation={1}
                            elevation={0}
                            sx={{
                                width: '100%', // Ajusta aquí para ocupar el 100% del ancho
                                ml: { sm: `${drawerWidth}px` },
                            }}
                            // color='default'
                            color='transparent'
                        >
                            <Toolbar >
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => setMobileOpen(true)}
                                    edge="start"
                                    sx={{ mr: 2, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Button color="inherit" component={RouterLink} to="/" style={buttonStyle}>
                                    Home
                                </Button>
                                <Button color="inherit" component={RouterLink} to="/sobre-nosotros" style={buttonStyle}>
                                    Sobre Nosotros
                                </Button>
                                {
                                    userInfo.cargo === 'ADMINISTRADOR' ? <>
                                        <Button color="inherit" component={RouterLink} to="/marcas" style={buttonStyle}>
                                            Marcas
                                        </Button>
                                        <Button color="inherit" component={RouterLink} to="/colores" style={buttonStyle}>
                                            Colores
                                        </Button>
                                        <Button color="inherit" component={RouterLink} to="/usuarios" style={buttonStyle}>
                                            Usuarios
                                        </Button>
                                    </> : ''
                                }
                                <Typography variant="h5" color="text.secondary" noWrap component="div" sx={{ flexGrow: 1 }}>
                                    {/* <strong>GRUPO EMPRESARIAL YIGA5</strong> */}
                                </Typography>

                                <ProfileMenu props={userInfo} />
                            </Toolbar>
                        </AppBar>
                    </>
                }
                <Box
                    component="main"
                    //p={props.isAuth ? 2 : 0}
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    {props.isAuth && <Toolbar />}
                    {props.children}
                </Box>
            </Box>
        </>
    )
}