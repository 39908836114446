import { auth, db, firebase } from '../../../firebase'
export const authState = (formData) => ({
    type: 'AUTH_SUCCESS',
    payload: {
        uid: formData.uid,
        displayName: formData.displayName,
        email: formData.email,
        cargo: formData.cargo,
        ...formData
    }
})
export const login = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'USER_LOGIN_REQUEST' })
        const sign = await auth.signInWithEmailAndPassword(formData.email, formData.password)
        console.log('datos del long', sign.user.emailVerified)
        //if (sign.user.emailVerified === true) {
        console.log(sign.user.emailVerified)
        const user = {
            uid: sign.user.uid
        }

        dispatch({
            type: 'USER_LOGIN_SUCCESS',
            payload: user
        })
        //} else {
        //    dispatch({
        //        type: 'USER_LOGIN_FAIL',
        //        payload: 'Debe verificar su cuenta antes de iniciar sesión',
        //    })
        //}
    } catch (error) {
        dispatch({
            type: 'USER_LOGIN_FAIL',
            payload: error,
        })
    }
}
export const logout = () => async (dispatch) => {
    dispatch({ type: 'USER_LOGOUT' })
    dispatch({ type: 'AUTH_LOGOUT' })
    await auth.signOut()
}