import { Grid as Grid2, GridColumn } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
    Box, Button, Stack, Container, Dialog,
    DialogActions, DialogContent, DialogTitle,
    Grid, Tooltip
} from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import moment from 'moment'

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const PdfRegistros = ({ info }) => {

    const container = React.useRef(null);
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(info);

    const openDialogBool = () => {
        /* if (open === false) {
            setOpen(true);
        } else {
            setOpen(true);
        } */
        setOpen(true);
    }

    useEffect(() => {
        /* openDialogBool() */
    }, [])

    const today = new Date();

    const [plan, setPlan] = useState('');

    const PageTemplate = (props) => {
        return (
            <div>
                {props.pageNum == 1 &&
                    <div
                        style={{
                            position: "absolute",
                            top: "20px",
                            width: "90%"
                        }}>
                        {/* <p align="left">
                            <img src={logo1} alt="soei" width="90" />
                        </p> */}
                        <Stack direction='row'
                            spacing={1}
                        >
                            {/* <img src={logo1} alt="soei" width="90" />
                            <img src={logo2} alt="soei" width="120" /> */}
                        </Stack>
                        <a style={{ fontSize: "12px", paddingTop: '10px' }} align="right">
                            <strong>RUC:   {'0992988061001'}</strong><br />
                            <strong>PBX:   {'0979370118'}</strong><br />
                            <strong>MAPASINGUE ESTE AV. VIA DAULE Nro. 1 km 5.5</strong>
                        </a>
                    </div>
                }
            </div >
        )
    };

    const firma = (
        <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '14px' }}>
            <Grid item xs={4}>
                <Box align="center" sx={{ marginTop: "70px", borderBottom: 1 }} >
                    <a ></a>
                </Box>
                <p align="center" style={{ fontSize: "13px" }}><strong>FIRMA</strong></p>
            </Grid>
        </Grid >
    )

    const FechaYRepresentanteLegal = (
        <Grid container spacing={7} alignItems="center" justifyContent="start" style={{ fontSize: '14px' }}>
            <Grid item xs={4}>
                <Box align="start" sx={{ marginTop: "70px", borderBottom: 1 }} >
                    <a ></a>
                </Box>
                <p align="start" style={{ fontSize: "13px" }}><strong>FECHA DE REPORTE</strong></p>
            </Grid>
            <Grid item xs={4}>
                <Box align="start" sx={{ marginTop: "70px", borderBottom: 1 }} >
                    <a></a>
                </Box>
                <p align="start" style={{ fontSize: "13px" }}><strong>REPRESENTANTE LEGAL</strong></p>
            </Grid>
        </Grid >
    )

    const bordes = {
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        borderTop: "1px solid black",
        padding: "8px", // Ajusta el padding para reducir el tamaño vertical de las celdas
        fontSize: "11px", // Ajusta el tamaño de fuente para reducir el tamaño vertical de las celdas
    }

    const headerColumn = (props) => {
        return (
            <a style={{ bordes }}>
                <span ><strong>{props.title}</strong></span>
                {props.children}
            </a>
        );
    };

    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            repeatHeaders: 'true',
            /* pageTemplate: PageTemplate, */
            paperSize: "A4",
            margin: { top: "3cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.5,
            fileName: `Reporte de Registro de Vehiculos ${moment(today).format("DD/MM/YYYY")}`,
        });
    };

    const tabla = (
        <TableContainer style={{ width: '100%' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{
                        "& th": {
                            color: "rgba(96, 96, 96)",
                            backgroundColor: "grey",
                            color: "white"
                        }
                    }}>
                        <TableCell color='' style={bordes}><strong>No</strong></TableCell>
                        <TableCell style={bordes}><strong>Placa</strong></TableCell>
                        <TableCell style={bordes}><strong>Marca</strong></TableCell>
                        <TableCell style={bordes}><strong>Propietario</strong></TableCell>
                        <TableCell style={bordes}><strong>Cedula</strong></TableCell>
                        <TableCell style={bordes}><strong>Color</strong></TableCell>
                        <TableCell style={bordes}><strong>Vehiculo empresa</strong></TableCell>
                        <TableCell style={bordes}><strong>Fecha ingreso</strong></TableCell>
                        <TableCell style={bordes}><strong>Hora ingreso</strong></TableCell>
                        <TableCell style={bordes}><strong>Fecha salida</strong></TableCell>
                        <TableCell style={bordes}><strong>Hora salida</strong></TableCell>
                        <TableCell style={bordes}><strong>Observaciones</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={item.id}>
                            <TableCell style={bordes}>{index + 1}</TableCell>
                            <TableCell style={bordes}>{item.placa}</TableCell>
                            <TableCell style={bordes}>{item.marca}</TableCell>
                            <TableCell style={bordes}>{item.propietario}</TableCell>
                            <TableCell style={bordes}>{item.cedula}</TableCell>
                            <TableCell style={bordes}>{item.color}</TableCell>
                            <TableCell style={bordes}>{item.vehiculo_local_empresa}</TableCell>
                            <TableCell style={bordes}>
                                {moment(item.fecha_ingreso.toDate()).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell style={bordes}>
                                {moment(item.hora_ingreso.toDate()).format("h:mm:ss a")}
                            </TableCell>
                            <TableCell style={bordes}>
                                {item.fecha_salida === null || item.fecha_salida === undefined || item.fecha_salida === '' ? '' : moment(item.fecha_salida.toDate()).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell style={bordes}>
                                {item.hora_salida === null || item.hora_salida === undefined || item.hora_salida === '' ? '' : moment(item.hora_salida.toDate()).format("h:mm:ss a")}
                            </TableCell>
                            <TableCell style={bordes}>{item.observaciones}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
    return (
        <>
            <Tooltip title="Descargar pdf">
                <Button
                    style={{ fontSize: "11px" }}
                    disableElevation
                    color="error"
                    variant="outlined"
                    size="small"
                    endIcon={<CloudDownloadIcon fontSize="small" />}
                    onClick={() => {
                        setOpen(true)
                        console.log('info', info)
                        console.log('info[0]', info[0])
                        info.forEach(async (obj) => {
                            console.log('obj.placa', obj.placa)
                        })

                    }}>Descargar pdf
                </Button>
            </Tooltip>


            <Dialog open={open} fullWidth maxWidth="lg">
                <DialogTitle>
                    {/* <strong>Descargar: {info.numContrato}</strong> */}
                </DialogTitle>
                <DialogContent>
                    <Container >
                        <div className="example-config">
                            <Button
                                onClick={exportPDFWithMethod}
                                style={{ marginLeft: "10px", marginBottom: "10px" }}
                                color="error" size='small' variant="outlined"
                                startIcon={<PictureAsPdfIcon />}>
                                <strong>DESCARGAR PDF</strong>
                            </Button>
                        </div>
                        <div className="border rounded p-2">
                            <PDFExport>
                                <div ref={container}>
                                    <Container maxWidth='xl'>
                                        <Box align="center" px={6} sx={{ justifyContent: 'center' }}>
                                            <p style={{ marginBottom: "6px", marginTop: "0px" }}><strong>Placam</strong></p>
                                            <p style={{ marginBottom: "6px", marginTop: "0px" }}><strong>Servicesmart S.A</strong></p>
                                            <p style={{ marginBottom: "6px", marginTop: "0px" }}><strong>Reporte de registro de vehiculos</strong></p>
                                        </Box>
                                        {tabla}
                                        <br />
                                        {FechaYRepresentanteLegal}
                                        {firma}
                                    </Container>
                                </div>
                            </PDFExport>
                        </div>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained"
                        onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>

        </>

    )
}
