import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db, firebase, auth } from "../../firebase";

export const RecoverPassword = ({ props }) => {
    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const RecoverPasswordFunction = async () => {

        try {
            /* firebase.auth().sendPasswordResetEmail('lianmejiag@gmail.com')
                .then(() => {
                    setOpen(false)
                    Swal.fire({ icon: "success", text: `Se ha enviado un correo de recuperacion al correo ${props.email}`, });
                })
                .catch((error) => {
                    console.log('error', error)
                }); */
            firebase
                .auth()
                .signInWithEmailAndPassword(props.email, props.password)
                .then(async (userCredential) => {
                    const user = userCredential.user;
                    console.log("Sign In SuccessFul!");

                    await firebase.auth().sendPasswordResetEmail(props.email)

                    setOpen(false)
                    Swal.fire({ icon: "success", text: `Se ha enviado un correo de recuperacion al correo ${props.email}`, });
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        } catch (e) {

        }
    }

    return (
        <>
            <Tooltip title="Recuperar contraseña">
                <Button onClick={() => onData()}>
                    <LockResetIcon color="success" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    ¿ESTA SEGURO DE ENVIAR UN ENLACE DE RECUPERACION AL CORREO <strong>{props.EMAIL}</strong>?
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} size="small"><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        RecoverPasswordFunction()
                        /* datosFlota() */
                    }} variant="contained"
                        color="success"
                        size="small"
                    >
                        ENVIAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};