import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, TextField, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../firebase";
/* import InputValidation from "../utils/InputValidation"; */

export const CreateNewColor = () => {
    const userAuth = useSelector(state => state.userAuth)
    const [nombreColor, setNombreColor] = useState('')

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const createNewColorFunction = async () => {

        const agendaDB3 = await db
            .collection('parameters')
            .doc('colores_vehiculos')
            .collection('colores')
            .where('color', '==', nombreColor.toUpperCase())
            .get();

        const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        if (data3[0] === undefined) {
            console.log('El color no se encuentra registrado')
            await db.collection('parameters').doc('colores_vehiculos').collection('colores').doc().set({
                color: nombreColor.toUpperCase(),
                created: firebase.firestore.FieldValue.serverTimestamp(),
            }).then(() => {
                Swal.fire({ icon: "success", title: "Nuevo color creado con exito" });
                setNombreColor('')
            }).catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
        } else {
            console.log('El color se encuentra registrado')
            Swal.fire({ icon: "warning", text: `El color que desea crear ya se encuentra registrado` });
        }
    }

    return (
        <>
            <Typography variant="button" >
                <Typography >
                    <strong>AGREGAR NUEVO COLOR</strong>
                </Typography >
            </Typography >
            <Box pb={2} pt={1}>
                <TextField
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }} value={nombreColor} onChange={(e) => setNombreColor(e.target.value)} fullWidth variant="outlined" size="small" inputProps={{
                        maxLength: 12,
                    }}
                />
            </Box>

            <Button
                onClick={() => createNewColorFunction()}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    nombreColor == ''}
            >
                CREAR NUEVO COLOR
            </Button>
        </>
    );
};
