import { Typography, Box, Tabs, Tab, Card, CardContent } from '@mui/material';
import React, { useState } from 'react'
import { CreateNewUser } from './CreateNewUser';
import { UsuariosList } from './UsuariosList';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const UsuariosPage = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box px={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Usuarios" {...tabs(0)} />
                            <Tab label="Crear usuario" {...tabs(1)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <UsuariosList />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CreateNewUser />
                        </TabPanel>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}