import React, { useState, useEffect } from 'react'
import { Box, CssBaseline } from '@mui/material';
import {
    BrowserRouter,
    Routes,
    Route,
    HashRouter
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { auth, db } from '../firebase'
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { authState } from '../redux/actions/account/authActions';

import { HomePage } from '../pages/HomePage';
import { LoginPage } from '../pages/LoginPage';
import { Header } from '../pages/Header';
import { AboutPage } from '../pages/AboutPage';
import { ColorsPage } from '../pages/ColoresPage/ColorsPage';
import { MarcasPage } from '../pages/MarcasPage/MarcasPage';
import { UsuariosPage } from '../pages/UsuariosPage/UsuariosPage';
export const AppRouters = () => {
    let theme = createTheme({
        typography: {
            fontFamily: 'Raleway, Arial',
        }
    });
    theme = responsiveFontSizes(theme);
    const dispatch = useDispatch();
    const [checking, setChecking] = useState(true);
    const [isAuth, setIsAuth] = useState(false)
    /* useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            if (userAuth) {

                const authUser = auth.currentUser;


                console.log(userAuth, 'auth')
                userAuth.getIdTokenResult().then(getIdTokenResult => {
                    console.log(userAuth.displayName)
                    dispatch(authState({
                        uid: userAuth.uid,
                        displayName: userAuth.displayName,
                        email: userAuth.email,
                        cargo: ''
                    }))
                })
                setIsAuth(true)

            } else {
                setIsAuth(false)
            }
            setChecking(false);
        });
    }, [setIsAuth, setChecking]) */
    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            if (userAuth) {
                console.log(userAuth, 'auth')
                db.collection('usuarios').doc(`${userAuth.uid}`).get()
                    .then((doc) => {
                        if (doc.exists) {
                            let dataClient = doc.data()
                            const dataT = {
                                uid: dataClient.uid,
                                displayName: dataClient.name,
                                email: dataClient.email,
                                cargo: dataClient.cargo,
                                password: dataClient.password
                            }
                            setChecking(false)
                            setIsAuth(true)
                            dispatch({
                                type: 'AUTH_ADD_ITEMS',
                                payload: dataT,
                            })
                        } else {
                            console.log("No se encontro usuario");
                        }
                    })
            } else {
                setChecking(false)
                setIsAuth(false)
            }
        });
    }, [setIsAuth, setChecking, dispatch])
    if (checking) {
        return ('')
    }
    return (
        <HashRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box sx={{ display: 'flex' }}>
                    {/* {isAuth && <Header />} */}
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 0, width: isAuth ? { sm: `calc(100% - ${300}px)` } : '100%' }}
                    >
                        {/* <Toolbar /> */}
                        <Header isAuth={isAuth}>
                            <Routes>
                                <Route path='/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <HomePage />
                                    </PrivateRoute>
                                } />

                                <Route path='/account/login/' element={
                                    <PublicRoute isAuth={isAuth}>
                                        <LoginPage />
                                    </PublicRoute>
                                } />

                                <Route path='/sobre-nosotros' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <AboutPage />
                                    </PrivateRoute>
                                } />

                                <Route path='/colores' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ColorsPage />
                                    </PrivateRoute>
                                } />

                                <Route path='/marcas' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <MarcasPage />
                                    </PrivateRoute>
                                } />

                                <Route path='/usuarios' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <UsuariosPage />
                                    </PrivateRoute>
                                } />

                                <Route path="solicitudes">

                                    {/* <Route path='aulas' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <SolicitudesAulas />
                                    </PrivateRoute>
                                } /> */}


                                </Route>

                            </Routes>
                        </Header>
                    </Box>
                </Box>
            </ThemeProvider>
        </HashRouter>
    )
}